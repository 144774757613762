import React from "react";

export function CoimexBadge({ badges }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      {badges.map((badge, index) => (
        <div key={index} className="flex flex-col items-center text-center">
          <img
            src={badge.image || "/placeholder.svg"}
            alt={badge.title}
            className="w-16 h-16 mb-4"
          />
          <h3 className="text-lg font-semibold mb-2">{badge.title}</h3>
          <p>{badge.text}</p>
        </div>
      ))}
    </div>
  );
}
