import { useEffect, useState, useRef } from "react";
import Link from "next/link";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Testemonial } from "../UI/components/Box/Testemonial";
import { TestimonialCard } from "../UI/components/Box/TestimonialCard";
import { EffectFade, Autoplay } from "swiper";
import { HeaderHome } from "../UI/components/Box/HeaderHome";
import { useTranslation } from "next-i18next";
import { WhatsAppWidget } from "react-whatsapp-widget";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";

import { Section } from "../UI/components/BadgeSection/Section";
import { Divider } from "../UI/components//Divider";
import { DividerSvg } from "../UI/components/DividerSvg";
import { CoimexBadge } from "../UI/components/Badge";

import ScrollingSection from "../UI/components/NewSolutions";
import MobileNewSolutionsSection from "../UI/components/MobileNewSolutions";
import MobileSolutionsSection from "../UI/components/MobileNewSolution";
import CountUpNumbers from "../UI/components/CountNumbers/CountUpNumbers";

import "react-whatsapp-widget/dist/index.css";
const OurMilestone = [
  {
    title: "How Does Coimex Work?",
    text: "Coimex, Chain of Importers & Exporters, is a global digital platform building a trustable community of traders, wholesalers, retailers and manufacturers through annual membership. The Platform connects you with trustable traders worldwide and builds long-term partnerships using the latest technology of mobile and web applications.",
    image: "/images/web-industry24.webp",
  },
  {
    title: "Coimex For Exporter",
    text: "Exporters enjoy the easiest and fastest way to showcase their products to trustable customers worldwide. Staying connected will empower the Exporters by giving them the opportunity to expand their businesses while creating awareness within the different regions of the seven continents.",
    image: "/images/web-industry32.webp",
  },
  {
    title: "Coimex For Importers",
    text: "Importers enjoy the advantages of being visible to the variety of category-leader suppliers worldwide. Staying connected will empower the Importers by catching the real-time opportunities of their categories and becoming competitive in their countries.",
    image: "/images/web-industry2.webp",
  },
  {
    title: "Benefits of Coimex Trading Model",
    text: "Coimex, integrates technology into the business world to enable safe trade for the actors of international trade. The transparent and informative structure of Coimex application empowers traders around the globe. The platform allows the ecosystem to be independent and free from outside intervention in order to maximize the credibility of Coimex members.",
    image: "/images/web-industry54.webp",
  },
];

export default function Home() {
  const { t } = useTranslation("common");
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";

  const [variant, setVariant] = useState("variant1");

  const textContainerRef = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);
  const maxTextScrollHeight = 840; // Adjust based on your design (gap between texts)
  const [scrollPosition, setScrollPosition] = useState(0);

  // Handle scroll event
  const handleScroll = (e) => {
    e.preventDefault(); // Prevent default scroll behavior

    const newScrollTop = scrollTop + e.deltaY; // Update scroll position based on mouse wheel delta

    if (newScrollTop >= 0 && newScrollTop <= maxTextScrollHeight) {
      setScrollTop(newScrollTop); // Constrain scroll within bounds
    }
    setScrollPosition(window.scrollY);
  };

  // Apply scroll transformation to the text container
  useEffect(() => {
    if (textContainerRef.current) {
      textContainerRef.current.style.transform = `translateY(-${scrollTop}px)`;
    }
  }, [scrollTop]);

  useEffect(() => {
    const timer1 = setTimeout(() => setVariant("variant2"), 100); // 100ms delay
    const timer2 = setTimeout(() => setVariant("variant3"), 1100); // 100ms + 1000ms

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const variants = {
    variant1: { opacity: 0, x: 50 },
    variant2: {
      opacity: 1,
      x: 0,
      transition: { duration: 1, ease: "easeOut" },
    },
    variant3: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.8, ease: "easeOut" },
    },
  };

  const TestemonialData = [
    {
      author: t("testmonial.8"),
      title: t("testmonial.7"),
      text: t("testmonial.6"),
      image: "/images/testimonial/alamgir-bin-ansar-india.png",
    },
    {
      author: t("testmonial.1"),
      title: t("testmonial.2"),
      text: t("testmonial.0"),
      image: "/images/testimonial/musa-gencer-sumfresh-turkiye.png",
    },
    {
      author: t("testmonial.5"),
      title: t("testmonial.4"),
      text: t("testmonial.3"),
      image: "/images/testimonial/khalid-alquood-gofresh-kuwait.png",
    },

    {
      author: t("testmonial.11"),
      title: t("testmonial.10"),
      text: t("testmonial.9"),
      image: "/images/testimonial/karen-ndulu-stekar-kenya.png",
    },
  ];
  const OurMilestone = [
    {
      title: t("homePage.0"),
      text: t("homePage.1"),
      image: "/images/web-industry24.webp",
    },
    { title: t("homePage.2"), text: t("homePage.3"), image: "/images/1.jpg" },
    { title: t("homePage.4"), text: t("homePage.5"), image: "/images/2.jpg" },
    {
      title: t("homePage.6"),
      text: t("homePage.7"),
      image: "/images/web-industry54.webp",
    },
  ];
  const CoimexBadge = [
    {
      text: t("homePage.12"),
      image: "/images/ico1.svg",
      title: t("homePage.20"),
    },
    {
      text: t("homePage.13"),
      image: "/images/ico2.svg",
      title: t("homePage.21"),
    },
    {
      text: t("homePage.14"),
      image: "/images/ico3.svg",
      title: t("homePage.22"),
    },
  ];

  const coimexStatistics = [
    {
      title: t("homePage.24"),
      count: "50",
      description: t("homePage.27"),
    },

    {
      title: t("homePage.25"),
      count: "40",
      description: t("homePage.28"),
    },
    {
      title: t("homePage.26"),
      count: "108",
      description: t("homePage.29"),
    },
  ];

  const DISABLED = true;

  const textVariants = {
    initial: {
      fontWeight: 600,
      textDecoration: "none",
    },
    hover: {
      fontWeight: 700,
      textDecoration: "none",
      textUnderlinePosition: "none",
      textDecorationSkipInk: "none",
      transition: {
        duration: 0.3,
        ease: "easeOut",
      },
    },
  };
  return (
    <>
      <Head>
        <title>Coimex</title>
        <meta name="description" content={t("meta.0")} />
        <meta name="keywords" content={t("meta.0")} />
        <meta itemProp="description" content={t("meta.0")} />
        <meta property="og:description" content={t("meta.0")} />
        <link rel="shortcut icon" href="/images/logo.png" />
      </Head>
      <div>
        <motion.div
          className="bg-cover flex items-center justify-center md:justify-start px-[12px] md:px-0 gap-1 bg-center bg-no-repeat sm:h-[650px] h-[852px] w-full sm:bg-[url('/images/Web-hero4.webp')] bg-[url('/images/mobile-hero4.png')]"
          initial="variant1"
          animate={variant}
          variants={variants}
        >
          {variant !== "variant3" ? (
            <motion.h1
              className="text-PrimaryE3 text-center text-2xl sm:hidden flex font-MontserratSemiBold mx-auto self-end mb-8"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              {/* {t("homePage.8")} */}
            </motion.h1>
          ) : (
            <div className="pl-8  md:block hidden">
              <HeaderHome />
            </div>
          )}

          <div className="bg-white/5 bg-opacity-50 px-3 pt-9 pb-7 mb-[15px] flex flex-col items-center justify-center sm:hidden mt-auto w-full rounded-[10px]">
            {" "}
            {/* Adjusted positioning */}
            <h1 className=" sm:flex text-[64px] leading-[64px] font-MontserratRegular font-bold text-center py-1 ">
              <span
                className="bg-clip-text"
                style={{
                  background:
                    "linear-gradient(90deg, #ECD7F3 0%, #9364A8 53.5%, #AC8EB9 87.72%, #FFFBFE 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                COIMEX
              </span>
            </h1>
            <h1 className=" text-[#FFFBFE] text-[1.5rem] md:text-[2rem] font-montserrat font-bold leading-6 text-center mb-3 whitespace-nowrap">
              {t("homePage.8")}
            </h1>
            <h2 className="text-[#FFFBFE] font-montserrat  text-[16px] w-full text-base font-normal leading-5 mb-1 text-center text-content py-2">
              {t("homePage.18")}
            </h2>
            <motion.a
              initial={{ fontWeight: 600, scale: 1, fontSize: "16px" }}
              whileHover={{
                color: "#FFFBFE",
                fontWeight: 700,
                background: "#4E1D68",
                scale: 1.05,
                transition: { duration: 0.3, ease: "easeOut" },
                lineHeight: "24px",
              }}
              href="https://wa.me/905324479299"
              rel="noopener noreferrer"
              target="_blank"
              className="flex justify-center items-center w-full h-[42px] rounded-full text-white font-montserrat font-semibold text-base leading-6 transition-all duration-300 ease-out 
         bg-[#572073]"
            >
              <motion.span
                initial={{ fontWeight: 600, scale: 1 }}
                className="transition-all duration-300"
              >
                {t("homePage.10")}
              </motion.span>
            </motion.a>
            <div className="mt-3 w-full cursor-pointer">
              <Link
                href={
                  isEnglish
                    ? "/pricing?package=exporter"
                    : "/tr/pricing?package=exporter"
                }
                className="cursor-pointer"
              >
                <motion.a
                  initial={{ fontWeight: 600, scale: 1, fontSize: "16px" }}
                  whileHover={{
                    color: "#FFFBFE",
                    fontWeight: 700,
                    background: "#0000001A",
                    scale: 1.05,
                    transition: { duration: 0.3, ease: "easeOut" },
                  }}
                  className="bg-white/5 bg-opacity-70 flex justify-center items-center w-full h-[42px] rounded-full border-2 border-white text-white font-montserrat font-semibold text-base relative overflow-hidden group transition-all duration-300"
                >
                  <motion.span
                    variants={textVariants}
                    initial="initial"
                    whileHover="hover"
                    className="relative z-10 group-hover:font-bold transition-all duration-300 ease-out whitespace-nowrap"
                  >
                    {t("homePage.11")}
                  </motion.span>
                  <div
                    className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                    style={{
                      background:
                        "linear-gradient(90deg, #6B2C91 0%, #9364A8 100%)",
                      opacity: 0.1,
                    }}
                  />
                </motion.a>
              </Link>
            </div>
          </div>
        </motion.div>

        <div className="flex flex-col justify-center bg-white ">
          {/* New Hompage Card */}
          <div className="flex md:flex-col m-auto md:justify-between w-full gap-y-2 md:w-11/12 mt-11 md:mt-20 flex-wrap">
            {/* Heading Section */}
            <h1 className="px-8 md:px-24">
              <span
                className="inline-block font-extrabold text-[32px] md:text-[60px] leading-[32px] md:leading-[80px] font-MontserratBold"
                style={{
                  background:
                    "linear-gradient(90deg, #742598 0%, #DEB2ED 33.33%, #9E68B6 66.67%, #D5ACE3 100%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {t("homePage.23")}
              </span>
              <br />
              <span
                className="inline-block font-extrabold text-[32px] md:text-[56px] leading-[42px] md:leading-[60px] font-montserrat"
                style={{
                  background:
                    "linear-gradient(90deg, #742598 0%, #DEB2ED 33.33%, #9E68B6 66.67%, #D5ACE3 100%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {t("homePage.23/sub")}
              </span>
            </h1>

            {/* Statistics Section */}
            <div className="w-full flex flex-wrap gap-x-20 gap-y-12 mt-6 mb-2 md:mb-12 px-8 md:px-24">
              {coimexStatistics.map((item, i) => (
                <div
                  key={i}
                  className="w-[280px] md:w-[320px] h-auto flex flex-col"
                >
                  {/* Count Number */}
                  <h3 className="text-[64px] md:text-[80px] font-bold text-fontColorB font-montserrat leading-[60px] md:leading-[80px]">
                    <CountUpNumbers end={item.count} />
                    {item.count === "50"
                      ? `${t("homePage.39")}+`
                      : item.count === "108" || item.count === "40"
                      ? "+"
                      : ""}
                  </h3>

                  {/* Divider */}
                  <div className="mb-4">
                    <DividerSvg />
                  </div>

                  {/* Title */}
                  <h3 className="text-[24px] leading-8 md:text-2xl font-semibold text-fontColorB font-montserrat">
                    {item.title}
                  </h3>

                  {/* Description */}
                  <p className="text-base text-fontColorB font-montserrat mt-2 leading-5 font-normal">
                    {item.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
          {/* New CoimexBadge */}
          <div className=" md:flex md:flex-col  md:justify-between gap-y-2 w-full mt-12 flex-wrap bg-[#EFF0F4]">
            {/* Badge Section */}
            <div className="w-full md:w-11/12 flex m-auto flex-wrap gap-x-20 gap-y-12 mt-6 mb-12 px-8 md:px-24">
              {CoimexBadge.map((item, i) => (
                <div
                  key={i}
                  className="w-[280px] md:w-[320px] h-auto flex flex-col"
                >
                  {/* Image */}
                  <img
                    src={item.image}
                    alt={item.text}
                    className="w-[60px] h-[65px]"
                  />

                  {/* Title */}
                  <h3 className="mt-6 font-MontserratMedium text-[24px] leading-[44px] text-fontColorB font-medium whitespace-nowrap">
                    {item.title}
                  </h3>

                  {/* Description */}
                  <p className="mt-6 font-montserrat text-base leading-[26px] text-fontColorB">
                    {item.text}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div
            className="w-full md:w-11/12 mx-auto py-2 md:py-12 px-8 md:px-24 relative"
            style={{
              backgroundImage: 'url("/images/badge-background.svg")',
              backgroundPosition: "top", // Align the top of the image with the bottom of the previous section
              backgroundAttachment: "fixed", // Fix the background image
              backgroundSize: "cover",
              height: "auto",
            }}
          >
            {/* Title Section */}
            <h1 className="font-bold mb-8">
              {/* First Line */}
              <span className="inline-block bg-trade-gradient bg-clip-text text-transparent text-[32px] md:text-6xl leading-[32px] md:leading-[70px] font-extrabold font-MontserratBold">
                {t("homePage.34")}
              </span>
              <br />
              {/* Second Line */}
              <span className="inline-block bg-gradient-to-r from-[#9E68B6] via-[#D5ACE3] to-[#9E68B6] bg-clip-text text-transparent text-[32px] md:text-6xl font-MontserratBold leading-[32px] md:leading-[70px]">
                {t("homePage.35")} &nbsp;
              </span>
              {/* Third Line */}
              <span className="inline-block bg-gradient-to-r from-[#9E68B6] via-[#D5ACE3] to-[#9E68B6] bg-clip-text text-transparent text-[32px] md:text-6xl leading-[32px] md:leading-[70px] font-extrabold font-montserrat">
                {t("homePage.36")} &nbsp;
              </span>
              {/* Fourth Line */}
              <span className="inline-block bg-gradient-to-r from-[#9E68B6] via-[#D5ACE3] to-[#9E68B6] bg-clip-text text-transparent text-[32px] md:text-6xl leading-[32px] md:leading-[70px] font-extrabold font-MontserratBold  ">
                {t("homePage.37")}
              </span>
            </h1>

            <div className="space-y-8">
              {OurMilestone.map((section, index) => (
                <div
                  key={section.title}
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    minHeight: "500px", // Adjust min-height as needed
                  }}
                  className="md:min-h-[500px]"
                >
                  {/* Background Image with Parallax Effect */}
                  <div
                    className="parallax-background"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundImage: `url(${section.backgroundImage})`, // Use the section's background image
                      backgroundPosition: "top",
                      backgroundSize: "cover",
                      transform: `translateY(${scrollPosition * 0.5}px)`, // Parallax effect
                      zIndex: -1, // Ensure the background is behind the content
                    }}
                  />

                  {/* Section Content */}
                  <div style={{ position: "relative", zIndex: 1 }}>
                    <Section {...section} />
                  </div>

                  {/* Divider (if not the last section) */}
                  {index < OurMilestone.length - 1 && <Divider />}
                </div>
              ))}
            </div>
          </div>

          <ScrollingSection />
          {/* <MobileNewSolutionsSection /> */}
          <h1 className="font-bold mb-5 text-left px-8 mt-[78px] md:hidden block">
            {/* First Line */}
            <span className="inline-block bg-trade-gradient bg-clip-text text-transparent text-[42px] leading-[48px] font-bold font-MontserratBold">
              {t("homePage.30")}
            </span>
            <br />
            {/* Second Line */}
            <span className="inline-block bg-gradient-to-r from-[#9E68B6] via-[#D5ACE3] to-[#9E68B6] bg-clip-text text-transparent text-[24px] font-bold font-MontserratBold leading-[24px]">
              {t("homePage.31")} &nbsp;
            </span>
          </h1>
          <MobileSolutionsSection />

          <div className="md:hidden">
            <Swiper
              spaceBetween={30}
              effect={"fade"}
              autoplay={{
                delay: 10500,
                disableOnInteraction: false,
              }}
              modules={[EffectFade, Autoplay]}
              className="w-11/12 mt-4 mb-11 bg-white"
            >
              {TestemonialData.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className="bg-white px-2 py-10 rounded-xl  w-11/12"
                >
                  <Testemonial item={item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className="md:block hidden ">
          <Swiper
            spaceBetween={30}
            effect={"fade"}
            autoplay={{
              delay: 10500,
              disableOnInteraction: false,
            }}
            modules={[EffectFade, Autoplay]}
            className="w-11/12 mt-4 mb-8"
          >
            {TestemonialData.map((item, index) => (
              <SwiperSlide
                key={index}
                className=" px-8 py-10 rounded-xl m-auto  w-9/12 "
              >
                <TestimonialCard item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}
