import React from "react";
import styles from "./ScrollingSection.module.css";
import { useTranslation } from "next-i18next";

const ScrollingSection = () => {
  const { t } = useTranslation("common");

  const textItems = [
    {
      title: t("homePage.32"),
      text: t("homePage.33"),
    },
    {
      title: t("homePage.40"),
      text: t("homePage.41"),
    },
    {
      title: t("homePage.42"),
      text: t("homePage.43"),
    },
  ];
  return (
    <div>
      {/* Scrolling Section */}
      <div className={styles.container}>
        {/* Left Side - Sticky Image */}
        <div className={styles.left}>
          <div className="absolute top-[10px] right-[15px]">
            <img
              src="/images/gif/ovalFull1.png"
              alt="background"
              style={{
                width: "457px", // Adjusted size to better fit design
                height: "477px",
                flexShrink: 0,
                opacity: 0.7,
                borderRadius: "50%",
              }}
            />
          </div>

          {/* Bottom Section with Adjusted SVGs */}
          <div className="absolute bottom-[-40px] left-0 flex justify-between w-full px-2 md:px-8">
            {/* Bottom Left Image (Money & Umbrella) */}
            <div className="absolute left-[-20px] bottom-6">
              <img
                src="/images/gif/ovalNew2.png"
                alt="background"
                style={{
                  width: "467px", // Increased for better proportion
                  height: "382px",
                  flexShrink: 0,
                  opacity: 0.7,
                }}
              />
            </div>
            {/* Bottom Right Image (Stock Market Globe) */}
            <div className="absolute right-[20px] bottom-0">
              <img
                src="/images/gif/ovalFull3.png"
                alt="background"
                style={{
                  width: "200px", // Adjusted to better fit
                  height: "200px",
                  flexShrink: 0,
                  opacity: 0.7,
                  borderRadius: "50%", // Ensuring circular shape
                }}
              />
            </div>
          </div>

          {/* Text Content */}
          <h1 className="absolute top-[57px] md:left-[105px] left-8 flex flex-col">
            <span
              className="font-MontserratBold font-bold text-[32px] md:text-[64px] inline-block text-[#fff] leading:[16px] md:leading-[96px] tracking-wide"
              style={{
                fontWeight: 700,
              }}
            >
              {t("homePage.30")}
            </span>
            <span
              className="inline-block text-[#fff] font-MontserratBold text-[24px] md:text-[48px] font-bold leading:[16px] md:leading-[64px] tracking-wide"
              style={{
                fontWeight: 700,
              }}
            >
              {t("homePage.31")}
            </span>
          </h1>
        </div>

        {/* Right Side - Scrollable Text Blocks */}
        <div className={styles.right}>
          <div className={styles.content}>
            {textItems.map((item, index) => (
              <div
                key={index}
                className={`${
                  index === 2 ? "mb-2 md:mb-[816px]" : "mb-0"
                }  md:h-[816px] flex flex-col justify-start text-[#38466D] bg-white`}
                style={{
                  color: "#38466D",
                  fontFeatureSettings: "'liga' off, 'clig' off",
                }}
              >
                {/* Title  */}
                <h2 className="text-[20px] md:text-[28px] font-semibold mb-2 sm:mb-4 leading-relaxed text-ellipsis">
                  {item.title}
                </h2>
                {/* Text */}
                <p className="text-[20px] leading-relaxed font-normal">
                  {item.text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollingSection;
