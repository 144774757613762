export const DividerSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="204"
    height="4"
    viewBox="0 0 204 4"
    fill="none"
  >
    <path
      opacity="0.5"
      d="M2 2H202"
      stroke="url(#paint0_linear_1869_4932)"
      strokeWidth="3"
      strokeLinecap="square"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1869_4932"
        x1="2"
        y1="2.5"
        x2="202"
        y2="2.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ECD7F3" />
        <stop offset="0.535" stopColor="#9364A8" />
        <stop offset="0.877224" stopColor="#AC8EB9" />
        <stop offset="1" stopColor="#FFFBFE" />
      </linearGradient>
    </defs>
  </svg>
);
