import React, { useRef, useEffect } from "react";
import styles from "./NewSolutionSection.module.css";
import { useTranslation } from "next-i18next";

const MobileNewSolutionsSection = () => {
  const { t } = useTranslation("common");
  const containerRef = useRef(null);
  const imageWrapperRef = useRef(null);
  const cardsWrapperRef = useRef(null);

  const textItems = [
    {
      title: t("homePage.32"),
      text: t("homePage.33"),
    },
    {
      title: t("homePage.40"),
      text: t("homePage.41"),
    },
    {
      title: t("homePage.42"),
      text: t("homePage.43"),
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (
        !containerRef.current ||
        !imageWrapperRef.current ||
        !cardsWrapperRef.current
      )
        return;

      const container = containerRef.current;
      const imageWrapper = imageWrapperRef.current;
      const cardsWrapper = cardsWrapperRef.current;

      const containerTop = container.offsetTop;
      const containerHeight = container.offsetHeight;
      const cardsWrapperHeight = cardsWrapper.offsetHeight;
      const windowScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;

      // Calculate the point where cardsWrapper reaches the top of the viewport
      const cardsStartScroll = containerTop + imageWrapper.offsetHeight;

      // Calculate the point where the bottom of cardsWrapper reaches the bottom of the viewport
      const cardsEndScroll = containerTop + containerHeight - windowHeight;

      if (
        windowScrollTop >= cardsStartScroll &&
        windowScrollTop <= cardsEndScroll
      ) {
        //  Cards are in view, so imageWrapper should NOT be sticky
        imageWrapper.style.position = "absolute";
        imageWrapper.style.top = `${cardsStartScroll - containerTop}px`; //stick right after the header
      } else if (windowScrollTop < cardsStartScroll) {
        // Before cards are in view, imageWrapper is sticky
        imageWrapper.style.position = "sticky";
        imageWrapper.style.top = "0";
      } else {
        //  After cards have scrolled past
        imageWrapper.style.position = "absolute";
        imageWrapper.style.top = `${
          containerHeight - imageWrapper.offsetHeight
        }px`; 
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); 

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={styles.container} ref={containerRef}>
      <h1 className="font-bold mb-5 text-left px-8">
        {/* First Line */}
        <span className="inline-block bg-trade-gradient bg-clip-text text-transparent text-[42px] font-bold font-MontserratBold">
          {t("homePage.30")}
        </span>
        <br />
        {/* Second Line */}
        <span className="inline-block bg-gradient-to-r from-[#9E68B6] via-[#D5ACE3] to-[#9E68B6] bg-clip-text text-transparent text-[24px] font-bold font-MontserratBold leading-[24px]">
          {t("homePage.31")} &nbsp;
        </span>
      </h1>

      <div className={styles.imageWrapper} ref={imageWrapperRef}>
        <div className="absolute top-[10px] left-0 flex justify-center w-full ">
          <div className="absolute top-[10px] left-0">
            <img
              src="/images/gif/ovalFull1.png"
              alt="background"
              style={{
                width: "322px",
                height: "436px",
                flexShrink: 0,
                opacity: 0.7,
                borderRadius: "50%",
              }}
            />
          </div>
          <div className="absolute top-[227px] right-[0px] z-50">
            <img
              src="/images/gif/ovalFull3.png"
              alt="background"
              style={{
                width: "275px",
                height: "375px",
                flexShrink: 0,
                opacity: 0.7,
                borderRadius: "50%",
              }}
            />
          </div>
        </div>

        {/* Bottom Section with Adjusted SVGs */}
        <div className="absolute bottom-[-20px] left-0 flex justify-between w-full px-2 md:px-8">
          {/* Bottom Left Image (Money & Umbrella) */}
          <div className="absolute left-[-10px] bottom-6">
            <img
              src="/images/gif/ovalNew2.png"
              alt="background"
              style={{
                width: "265px",
                height: "316.999px",
                flexShrink: 0,
                opacity: 0.7,
              }}
            />
          </div>
        </div>
      </div>

      {/* Cards Container */}
      <div className={styles.cardsWrapper} ref={cardsWrapperRef}>
        {textItems.map((item, index) => (
          <div className={styles.card} key={index}>
            <h2 className={styles.textTitle}>{item.title}</h2>
            <p className={styles.textContent}>{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileNewSolutionsSection;
