import React from "react";
import CountUp from "react-countup";

const CountUpNumbers = ({ end }) => {
  return (
    <CountUp
      scrollSpyDelay={100}
      scrollSpyOnce
      duration={4}
      enableScrollSpy
      end={end}
    />
  );
};

export default CountUpNumbers;
