import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

export function Section({ title, text, image, isReversed }) {
  const [isHovered, setIsHovered] = useState(false);

  const variants = {
    initial: {
      scale: 1,
      opacity: 1,
      transition: { duration: 0.5, ease: "easeOut" },
    },
    hovered: {
      scale: 1.12,
      opacity: 1,
      transition: { duration: 0.5, ease: "easeOut" },
    },
  };

  return (
    <div className="mb-2 md:mb-16 w-full">
      <div
        className={`flex flex-col ${
          isReversed ? "md:flex-row-reverse" : "md:flex-row"
        } gap-5 items-center`}
      >
        {/* Image Section */}
        <div
          className="md:w-1/2 relative overflow-hidden rounded-[8px]"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            background: isHovered
              ? "none"
              : "linear-gradient(287deg, #FFFBFE 2.09%, #ECD7F3 99.85%, #9364A8 73.68%, #9364A8 98.83%)",
          }}
        >
          {/* Image with Animation */}
          <motion.img
            src={image || "/placeholder.svg"}
            alt={title || "Image"}
            className="w-full h-[300px] object-cover rounded-[8px]"
            variants={variants}
            animate={isHovered ? "hovered" : "initial"}
            style={{
              filter: isHovered ? "none" : "opacity(0.95)",
              mixBlendMode: "multiply",
            }}
          />
        </div>

        {/* Text Section */}
        <div
          className={`md:w-1/2 mt-auto ${
            isReversed ? "md:text-right text-left" : ""
          } `}
        >
          <h1
            className="text-fontColorB text-[24px] font-MontserratBold mb-4"
            style={{
              lineHeight: "24px",
              fontWeight: 700,
              fontStyle: "normal",
            }}
          >
            {title}
          </h1>
          <p
            className="text-fontColorB text-[20px] leading-[24px] md:leading-[30px] mb-2 font-montserrat"
            style={{
              fontWeight: 400,
              fontFamily: "Montserrat",
              fontStyle: "normal",
            }}
          >
            {text}
          </p>
        </div>
      </div>
    </div>
  );
}
