import React from "react";
import styles from "./NewSolutionSection.module.css";
import { useTranslation } from "next-i18next";

const MobileSolutionsSection = () => {
  const { t } = useTranslation("common");

  const textItems = [
    {
      title: t("homePage.32"),
      text: t("homePage.33"),
    },
    {
      title: t("homePage.40"),
      text: t("homePage.41"),
    },
    {
      title: t("homePage.42"),
      text: t("homePage.43"),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <img src="/images/gif/homegif.gif" alt="Background Image" />
        <div className="absolute top-[10px] left-0 flex justify-center items-center w-full ">
          <div className="absolute top-[227px] right-[0px] z-10">
            <img
              src="/images/gif/ovalFull3.png"
              alt="background"
              style={{
                width: "325px",
                height: "325px",
                flexShrink: 0,
                opacity: 0.7,
                borderRadius: "50%",
              }}
            />
          </div>

          {/* Oval 1 (top image) */}
          <div className="absolute top-[59px] left-[-100px] z-20">
            <img
              src="/images/gif/ovalFull1.png"
              alt="background"
              style={{
                width: "322px",
                height: "336px",
                flexShrink: 0,
                opacity: 0.7,
                borderRadius: "50%",
              }}
            />
          </div>
        </div>
        <div className="absolute bottom-[-20px] left-0 flex justify-between w-full px-2 md:px-8">
          {/* Bottom Left Image (Money & Umbrella) */}
          <div className="absolute left-[-10px] bottom-6">
            <img
              src="/images/gif/ovalNew2.png"
              alt="background"
              style={{
                width: "345px",
                height: "336.999px",
                flexShrink: 0,
                opacity: 0.7,
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.cardsWrapper}>
        {textItems.map((item, index) => (
          <div className={styles.card} key={index}>
            <h2 className={styles.textTitle}>{item.title}</h2>
            <p className={styles.textContent}>{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileSolutionsSection;
