// @flow
import { Button } from "@mui/material";
import * as React from "react";
import Link from "next/link";
import { motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import { Scale } from "@mui/icons-material";

export const HeaderHome = (props) => {
  const { t } = useTranslation("common");
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";

  const textVariants = {
    initial: {
      fontWeight: 600, // Default font weight
      textDecoration: "none", // No underline by default
    },
    hover: {
      fontWeight: 700, // Bold on hover
      textDecoration: "none", // Underline on hover
      textUnderlinePosition: "none", // Prevent underline from going under descenders
      textDecorationSkipInk: "none", // Prevent skipping ink
      transition: {
        duration: 0.3, // Animation duration
        ease: "easeOut",
      },
    },
  };

  return (
    <div className="bg-white/5 bg-opacity-50 ml-[92px] rounded-xl sm:flex px-9 py-[30px] w-[438px] items-center hidden flex-col mt-16">
      <h1 className="hidden sm:flex text-[88px] font-MontserratRegular font-bold self-start px-2 ">
        <span
          className="bg-clip-text"
          style={{
            background:
              "linear-gradient(90deg, #ECD7F3 0%, #9364A8 53.5%, #AC8EB9 87.72%, #FFFBFE 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          COIMEX
        </span>
      </h1>
      <h1 className="text-[#FFFBFE] px-2 text-[2rem] text-center sm:flex hidden font-montserrat font-bold leading-6 self-start mb-6 whitespace-nowrap">
        {t("homePage.8")}
      </h1>

      <h2 className="font-montserrat text-[#FFFBFE] px-2 text-[18px] w-full text-base leading-7 mb-4 ">
        {t("homePage.18")}
      </h2>
      <motion.a
        initial={{ fontWeight: 600, scale: 1 }}
        whileHover={{
          color: "#FFFBFE",
          fontWeight: 700,
          background: "#4E1D68",
          scale: 1.05,
          transition: { duration: 0.3, ease: "easeOut" },
          lineHeight: "24px",
        }}
        href="https://wa.me/905324479299"
        rel="noopener noreferrer"
        target="_blank"
        className="flex justify-center items-center w-full h-[42px] rounded-full text-white font-montserrat text-base transition-all duration-300 ease-out 
             bg-[#572073]"
      >
        <motion.span
          initial={{ fontWeight: 600, scale: 1 }}
          className="transition-all duration-300"
        >
          {t("homePage.10")}
        </motion.span>
      </motion.a>

      <div className="mt-3 w-full cursor-pointer">
        <Link
          href={
            isEnglish
              ? "/pricing?package=exporter"
              : "/tr/pricing?package=exporter"
          }
          className="cursor-pointer"
        >
          <motion.a
            initial={{ fontWeight: 600, scale: 1 }}
            whileHover={{
              color: "#FFFBFE",
              fontWeight: 700,
              background: "#0000001A",
              scale: 1.05,
              transition: { duration: 0.3, ease: "easeOut" },
            }}
            className="bg-white/5 bg-opacity-70 flex justify-center items-center w-full h-[42px] rounded-full border-2 border-white text-white font-montserrat font-semibold text-base relative overflow-hidden group transition-all duration-300"
          >
            <motion.span
              variants={textVariants}
              initial="initial"
              whileHover="hover"
              className="relative z-10 group-hover:font-bold transition-all duration-300 ease-out whitespace-nowrap"
            >
              {t("homePage.11")}
            </motion.span>
            <div
              className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              style={{
                background: "linear-gradient(90deg, #6B2C91 0%, #9364A8 100%)",
                opacity: 0.1,
              }}
            />
          </motion.a>
        </Link>
      </div>
    </div>
  );
};
